import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import TextTile from "../components/TextTile";
import {
	Text,
	Flex,
	Image,
	Button,
	createStyles,
	Indicator,
} from "@mantine/core";
import Swaggin from "../assets/images/swaggin.svg";
import Besoul from "../assets/images/besoul.svg";
import EAR_Logo_3 from "../assets/svg/logotypes/EAR_Logo_3.svg";
import HubFinTech from "../assets/images/logo_hubfintech.webp";

const useStyles = createStyles(() => ({
	indicator: {
		"& .mantine-Indicator-indicator": {
			fontSize: 11,
		},
	},
	button: {
		fontWeight: 600,
		fontSize: 16,
		backgroundColor: "#002E81",
		transition: "all 0.2s",
		"&:hover": {
			textDecoration: "none",
			backgroundColor: "#002E81",
			color: "white",
		},
	},
}));

const Background = styled(Row)`
	border-top-radius: 100px;
`;
const OurWork = (props) => {
	const { OUR_WORK, OUR_WORK2, OUR_WORK3, OUR_WORK4, OUR_WORK5, OUR_WORK6 } =
		props.content;
	const { classes } = useStyles();

	return (
		<Background className="justify-content-center my-3" id="OurWork">
			<Col xs={10} md={10} lg={10}>
				<TextTile
					subtitle={OUR_WORK.subtitle}
					text={OUR_WORK.text}
					alignment="justify"
				/>
				<Indicator
					inline
					label={OUR_WORK2.label}
					size={16}
					color="#00A99D"
					offset={7}
					className={classes.indicator}
				>
					<Text align="left" size={35} c="black">
						{OUR_WORK2.subtitle}
					</Text>
				</Indicator>
				<Flex
					m="md"
					align="center"
					justify="space-evenly"
					wrap="wrap"
					direction={{
						base: "column",
						xs: "column",
						sm: "column",
						md: "row",
						lg: "row",
						xl: "row",
					}}
					id="products"
				>
					<Flex
						direction="column"
						justify="center"
						align="center"
						m="md"
						id="besoul"
						maw={448}
					>
						<Image src={Besoul} mb="md" mah={84} />
						<Text size={20} align="center" mt={30}>
							{OUR_WORK3.text}
						</Text>
					</Flex>
					<Flex
						direction="column"
						justify="center"
						align="center"
						m="md"
						id="ear"
						maw={448}
					>
						<Image src={EAR_Logo_3} mb="md" mah={84} maw={227} />
						<Text size={20} align="center" mt={15}>
							{OUR_WORK5.text}
						</Text>
					</Flex>
					<Flex
						direction="column"
						justify="center"
						align="center"
						m="md"
						id="swaggin"
						maw={448}
					>
						<Image src={Swaggin} mb="md" mah={125} />
						<Text size={20} align="center" mt={5}>
							{OUR_WORK4.text}
						</Text>
					</Flex>
					<Flex
						direction="column"
						justify="center"
						align="center"
						m="md"
						id="hubfintech"
						maw={448}
					>
						<Image src={HubFinTech} mb="md" maw={200} />
						<Text size={20} align="center" mt={5}>
							{OUR_WORK6.text}
						</Text>
					</Flex>
				</Flex>
				<Flex direction="column" align="center" justify="center" m="xl">
					<Button
						component="a"
						href="/Apps"
						radius="xl"
						color="#002E81"
						className={classes.button}
					>
						{OUR_WORK2.button}
					</Button>
				</Flex>
			</Col>
		</Background>
	);
};

export default OurWork;
